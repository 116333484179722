import { isEmpty, set, uniqBy } from "lodash"
import moment from "moment"
import {
  getUpdatedChatsAndConversations,
  getUpdatedChatsAndConversationsWithStatus,
  getUpdatedChatsAndConversationsWithMapping,
  getSocketMessageRecivedConnection,
} from "../../pages/WhatsappWebUI/whatsappWebHelpers"
import {
  WHATSAPP_WEB_ACTION_TYPES,
  MOMENT_DATE_FORMAT_TYPES,
  MESSAGE_READ_RECEIPTS_MAP,
} from "../../constants"
import * as types from "./actionTypes"

const intialState = {
  actionType: WHATSAPP_WEB_ACTION_TYPES.SELECT,
  contactsSearchKey: "",
  searchFilter: "",
  chatItems: [],
  totalContacts: 0,
  selectedChatItem: null,
  conversations: [],
  chatsPaginationData: {},
  conversationPaginationData: {},
  totalConversations: 0,
  listAndReplyMessages: undefined,
  scheduledMessages: [],
  scheduleMessageActionData: null,
  marketOptOutMobileNumbers: [],
  showChartOptionsForMenu: false,
  agentsData: [],
  isClosedConversion: undefined,
  labels: [],
  isQuickReplyModal: false,
}
let updatedChatItemsWithStatus
const WhatsAppWebReducer = (state = intialState, action) => {
  switch (action.type) {
    case types.SELECTION_STATE_FOR_COMPONENTS:
      state = {
        ...state,
        actionType: action.payload,
      }
      break
    case types.GET_AGENTS_RESPONSE:
      state = {
        ...state,
        agentsData: action.payload,
      }
      break
    case types.GET_CHAT_ITEMS_RESPONSE:
      const { chatItems, totalContacts, updatedChatsPaginationData } = action.payload

      state = {
        ...state,
        chatItems,
        totalContacts,
        chatsPaginationData: !isEmpty(updatedChatsPaginationData)
          ? updatedChatsPaginationData
          : state.chatsPaginationData,
      }
      break
    case types.SET_WHATSAPP_WEB_STATE_BY_KEY:
      const { key, value } = action.payload

      if (Array.isArray(key) && Array.isArray(value)) {
        key?.forEach((key, idx) => set(state, key, value[idx]))
      } else if (typeof key === "string") {
        set(state, key, value)
      }
      break
    case types.GET_CONVERSATIONS_RESPONSE:
      const { conversations, conversationPaginationData, totalConversations } =
        action.payload

      state = {
        ...state,
        conversations,
        totalConversations,
        conversationPaginationData: !isEmpty(conversationPaginationData)
          ? { ...conversationPaginationData }
          : state.conversationPaginationData,
      }
      break
    case types.SOCKET_MESSAGE_RECEIVED:
      const { updatedConversations, updatedChatItems, updatedSelectedChatItem } =
        getUpdatedChatsAndConversations(
          state,
          action.payload,
          action.masterPhoneNumber
        )
      state = {
        ...state,
        conversations: updatedConversations,
        totalConversations: updatedConversations?.length,
        chatItems: updatedChatItems?.length > 0 ? updatedChatItems : state.chatItems,
        selectedChatItem: updatedSelectedChatItem ?? state.selectedChatItem,
      }
      break
    case types.SOCKET_MESSAGE_MAPPING_RECEIVED:
      const { updatedConversationsWithMapping, updatedChatItemsWithMapping } =
        getUpdatedChatsAndConversationsWithMapping(state, action.payload)

      state = {
        ...state,
        conversations:
          updatedConversationsWithMapping?.length > 0
            ? updatedConversationsWithMapping
            : state.conversations,
        chatItems:
          updatedChatItemsWithMapping?.length > 0
            ? updatedChatItemsWithMapping
            : state.chatItems,
      }
      break
    case types.SET_TICKET_STATUS_FOR_MESSAGE_RECEIVED_CONTACTS_REQUEST:
      let contactData = action.payload
      const chatItemIndex = state.chatItems?.findIndex(
        item => item?.phoneNumber === contactData?.from
      )
      let updateChartItems
      if (chatItemIndex !== -1) {
        updateChartItems = state.chatItems
        const updatedChatItem = {
          ...state?.chatItems[chatItemIndex],
          message: "ddddd",
          ticketInfo: {
            ticketId: contactData?.id,
            status: contactData?.status,
            ticketDueDate: null,
            updatedTs: "",
            assigneeId: "",
            reporterId: "",
            replyOwner: "bot",
          },
        }
        updateChartItems.splice(chatItemIndex, 1, updatedChatItem)
        console.log("redurecer=>", updateChartItems, updatedChatItem)
      }
      console.log("redurecer=>", {
        a: action.payload,
        updateChartItems,
        contactData,
        chatItemIndex,
      })
      state = {
        ...state,
        chatItems: updateChartItems,
      }
      break
    case types.SOCKET_MESSAGE_STATUS_RECEIVED:
      const { updatedConversationsWithStatus, updatedChatItemsWithStatus } =
        getUpdatedChatsAndConversationsWithStatus(state, action.payload)

      state = {
        ...state,
        conversations:
          updatedConversationsWithStatus?.length > 0
            ? updatedConversationsWithStatus
            : state.conversations,
        chatItems:
          updatedChatItemsWithStatus?.length > 0
            ? updatedChatItemsWithStatus
            : state.chatItems,
      }
      break
    case types.GET_ENABLE_CHAT_DETAILS_RESPONSE:
      state = {
        ...state,
        chatItems: action.payload?.length > 0 ? action.payload : state.chatItems,
      }
      break
    case types.GET_LIST_AND_REPLAY_MESSAGES_RESPONSE:
      state = {
        ...state,
        listAndReplyMessages: action.payload,
      }
      break
    case types.FETCH_MORE_CONVERSATIONS_RESPONSE:
      const { fetchedConversations, conversationsPaginationData } = action.payload

      if (
        state.conversationPaginationData?.page !==
          conversationsPaginationData?.page &&
        fetchedConversations?.length
      ) {
        const updatedConversationsArr = state?.conversations || []
        updatedConversationsArr.unshift(...fetchedConversations)

        state = {
          ...state,
          conversations: updatedConversationsArr,
          conversationPaginationData: !isEmpty(conversationsPaginationData)
            ? { ...conversationsPaginationData }
            : state.conversationPaginationData,
        }
      }

      if (fetchedConversations?.length) {
        setTimeout(() => {
          const entry =
            document.querySelectorAll(".conversation")[fetchedConversations.length]
          entry?.scrollIntoView?.({ behavior: "auto" })
        })
      }
      break
    case types.INITIATE_NEW_CHAT:
      const existingChatItems = [...(state.chatItems || [])]
      const existingChatItemIndex = existingChatItems.findIndex(
        item => item?.phoneNumber === action.payload?.toNumber
      )
      const existingChatItem = existingChatItems[existingChatItemIndex]

      existingChatItems.splice(existingChatItemIndex, 1, {
        ...existingChatItem,
        phoneNumber: action?.payload?.toNumber,
        messageType: "text",
        status: "",
        channel: "API",
        contactName: action?.payload?.name || action?.payload?.toNumber,
      })
      console.log("INITIATE_NEW_CHAT", existingChatItem)
      state = {
        ...state,
        chatItems: existingChatItems,
        selectedChatItem: existingChatItemIndex,
        actionType: WHATSAPP_WEB_ACTION_TYPES.VIEW_MESSAGES,
        conversations: [],
      }
      break
    case types.UPDATE_CONVERSATION_WITH_EMITTED_MESSAGE:
      const {
        messageId,
        whatsAppMessageId,
        fromMobileNo,
        toMobileNo,
        messageType,
        message,
        url,
        conversationType,
        status,
        channel,
        swipeMessages,
        metaRequestObj,
        captions,
        updatedTs,
      } = action.payload || {} //message,dispatch
      const updatedConversationsArray = [...(state.conversations || [])]
      let isUpdateConversion =
        state.selectedChatItem ===
        state?.chatItems?.findIndex(x => x.phoneNumber === toMobileNo)
      const updatedChatItem = {
        ...(state?.chatItems?.find(x => x.phoneNumber === toMobileNo) || {}),
        updatedTs,
        message,
        captions,
        metaRequestObj,
        status: isUpdateConversion ? "sent" : MESSAGE_READ_RECEIPTS_MAP.UNREAD,
      }
      if (isUpdateConversion) {
        updatedConversationsArray.push({
          messageId: messageId,
          whatsAppMessageId: whatsAppMessageId,
          fromMobileNo: fromMobileNo,
          toMobileNo: toMobileNo,
          messageType: messageType,
          message,
          url,
          conversationType,
          status,
          channel,
          swipeMessages,
          metaRequestObj,
          captions,
          updatedTs,
        })
      }

      console.log(
        "updatedChatItemsArray=>1",
        updatedChatItem,
        isUpdateConversion,
        isUpdateConversion ? "sent" : MESSAGE_READ_RECEIPTS_MAP.UNREAD,
        state?.chatItems
      )
      const updatedChatItemsArray = state.chatItems
      updatedChatItemsArray.splice(
        state?.chatItems?.findIndex(x => x.phoneNumber === toMobileNo),
        1
      )
      updatedChatItemsArray.unshift(updatedChatItem)
      console.log(
        "updatedChatItemsArray",
        state.selectedChatItem,
        updatedChatItem,
        updatedChatItemsArray
      )
      state = {
        ...state,
        conversations: updatedConversationsArray,
        chatItems: updatedChatItemsArray,
        selectedChatItem: isUpdateConversion ? 0 : state?.selectedChatItem,
        // chatItems: state.chatItems?.map((chat, idx) => {
        //   if (state.selectedChatItem === idx) {
        //     return {
        //       ...chat,
        //       message,
        //       captions,
        //       metaRequestObj,
        //     }
        //   }
        //   return chat
        // }),
      }

      // scroll to the last message.
      setTimeout(() => {
        const entry = Array.from(document.querySelectorAll(".conversation")).at(-1)
        entry?.scrollIntoView?.({ behavior: "auto" })
      })
      break
    case types.GET_WHATSAPP_SCHEDULED_MESSAGES_RESPONSE:
    case types.CREATE_OR_UPDATE_WHATSAPP_SCHEDULED_MESSAGE_RESPONSE:
      state = {
        ...state,
        scheduledMessages: action.payload,
      }
      break
    case types.GET_MARKET_OPT_OUT_MOBILE_NUMBERS_RESPONSE:
      const { marketOptOutMobileNumbers } = action.payload

      state = {
        ...state,
        marketOptOutMobileNumbers,
      }
      break
    case types.SHOW_CHART_OPTIONS_MENU_REQUEST:
      state = {
        ...state,
        showChartOptionsForMenu: action.payload,
      }
      break
    case types.SHOW_MODEL_FOR_CLOSED_STATUS_REQUEST:
      state = {
        ...state,
        isClosedConversion: action.payload,
      }
      break
    case types.GET_LABELS_BASED_ON_TICKET_ID_RESPONSE:
      state = {
        ...state,
        labels: action.payload,
      }
      break
    case types.SHOW_QUICK_REPLY_MODAL:
      state = { ...state, isQuickReplyModal: action.payload }
    default:
      state = { ...state }
  }
  return state
}

export default WhatsAppWebReducer
