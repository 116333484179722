import { select, takeLatest, call, put, all, fork } from "redux-saga/effects"
import { cloneDeep, orderBy } from "lodash"
import { CHAT_RECORDS_PER_SCROLL, CONVERSATIONS_PER_SCROLL } from "../../constants"
import { asBoolean } from "../../helpers/helperUtils"
import * as urls from "../../helpers/url_helper"
import { Post } from "../../helpers/api_helper"
import * as types from "./actionTypes"
import * as actions from "./actions"

const chatsPagesRequested = {}

function* getMarketOptOutMobileNumbers() {
  let marketOptOutMobileNumbers = []

  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    const pdpaResponse = yield Post(urls.GET_CAMPAIGN_PDPA_DATA, {
      cin: cin,
      isDeleted: "false",
      pdpaChannel: "Whatsapp",
      pdpaIdentity: "",
    })

    marketOptOutMobileNumbers = pdpaResponse?.data?.pdpaResponse?.map(
      x => x.pdpaIdentity
    )
    console.log("_getMarketOptOutMobileNumbers_pdpaResponse", {
      pdpaResponse,
      marketOptOutMobileNumbers,
    })
  } catch (err) {
    console.error("_getMarketOptOutMobileNumbers_catch", err)
  }

  yield put(actions.getMarketOptOutMobileNumbersResponse(marketOptOutMobileNumbers))
}
function* getMarketOptOutMobileNumbersWatcher() {
  yield takeLatest(
    types.GET_MARKET_OPT_OUT_MOBILE_NUMBERS_REQUEST,
    getMarketOptOutMobileNumbers
  )
}

function* getChatItems(action) {
  let chatItems = [],
    totalContacts = 0,
    updatedChatsPaginationData,
    alertMessageData

  try {
    const { pageNumber, searchNumber } = action.payload
    const loginState = (yield select())["loginReducer"]
    const cin = loginState?.sessionStateData?.ciNo
    const { wba_phone_no: masterPhoneNumber } =
      loginState?.wbaDetails?.wba_phone_numbers?.[0] || {}

    const chatsPaginationData = (yield select())["WhatsAppWebReducer"]
      ?.chatsPaginationData
    const isValidRequestedPageNo =
      pageNumber <= (chatsPaginationData?.totalPages || 0)

    if (
      (pageNumber === 0 || isValidRequestedPageNo) &&
      (!chatsPagesRequested[pageNumber] || searchNumber || searchNumber === "")
    ) {
      const requestObj = {
        cin: cin,
        masterPhoneNo: masterPhoneNumber,
        searchByNumber: searchNumber,
        agentId: 0,
        status: true,
      }

      const response = yield call(Post, urls.GET_WHATSAPP_CHAT_LIST, requestObj)
      console.log("_getChatItems_response", response.data)

      if (response?.data?.status) {
        chatsPagesRequested[pageNumber] = true

        if (pageNumber === 0) {
          chatItems = response.data?.data
        } else {
          chatItems = cloneDeep(
            (yield select())["WhatsAppWebReducer"]?.chatItems || []
          )
          chatItems.push(...(response.data?.data || []))
        }

        updatedChatsPaginationData = {
          page: response?.data?.page || 0,
          totalContacts: response?.data?.totalContacts || chatItems?.length || 0,
          totalPages: response?.data?.totalPages || 1,
        }
        totalContacts = response?.data?.totalContacts || chatItems?.length || 0
      } else {
        alertMessageData = {
          status: response?.data?.status ?? false,
          message:
            response?.data?.message || "Unable to fetch whatsapp web contacts",
        }
      }
    }
  } catch (err) {
    console.error("_getChatItems_catch", { err, action })
    alertMessageData = {
      status: false,
      message: "Failed to fetch whatsapp web contacts",
    }
  }

  console.log("__saga", {
    chatItems,
    totalContacts,
    updatedChatsPaginationData,
    alertMessageData,
  })
  yield put(
    actions.getChatItemsResponse(
      chatItems,
      totalContacts,
      updatedChatsPaginationData,
      alertMessageData
    )
  )
}
function* getChatItemsWatcher() {
  yield takeLatest(types.GET_CHAT_ITEMS_REQUEST, getChatItems)
}

function* getConversations(action) {
  let conversations,
    conversationPaginationData,
    totalConversations = 0,
    alertMessageData

  try {
    const { pageNumber, phoneNumber } = action.payload
    const loginState = (yield select())["loginReducer"]
    const cin = loginState?.sessionStateData?.ciNo
    const { wba_phone_no: masterPhoneNumber } =
      loginState?.wbaDetails?.wba_phone_numbers?.[0] || {}

    const requestObj = {
      cin: cin,
      masterPhoneNo: masterPhoneNumber,
      mobileNo: phoneNumber,
      pageNo: pageNumber,
      per_page: CHAT_RECORDS_PER_SCROLL,
    }

    console.log("getConversations=========>", requestObj)

    const response = yield call(
      Post,
      urls.GET_WHATSAPP_CHAT_CONVERSATION,
      requestObj
    )
    console.log("_getConversations_response", response.data)

    if (response?.data?.status) {
      conversations = orderBy(response?.data?.data || [], "updatedTs", "asc")
      conversationPaginationData = {
        phoneNumber: response?.data?.phoneNumber,
        page: response?.data?.page,
        total_Pages: response?.data?.total_Pages,
        total_records: response?.data?.total_records,
      }
      totalConversations = response?.data?.total_records
    } else {
      alertMessageData = {
        status: response?.data?.status ?? false,
        message: response?.data?.message || "Unable to fetch whatsapp conversations",
      }
    }
  } catch (err) {
    console.error("_getConversations_catch", err)
    alertMessageData = {
      status: false,
      message: "Failed to fetch whatsapp conversations",
    }
  }

  yield put(
    actions.getConversationsResponse(
      conversations,
      conversationPaginationData,
      totalConversations,
      alertMessageData
    )
  )
}
function* getConversationsWatcher() {
  yield takeLatest(types.GET_CONVERSATIONS_REQUEST, getConversations)
}

function* getEnableChatDetails(action) {
  let updatedChatItems, alertMessageData

  try {
    const { cin, phoneNumber, masterPhoneNumber } = action.payload

    const requestObj = {
      cin: cin,
      masterPhoneNo: masterPhoneNumber,
      mobileNo: phoneNumber,
    }

    const response = yield call(Post, urls.ENABLE_CHAT, requestObj)
    console.log("_getEnableChatDetails_response", response?.data)

    if (response?.data?.status) {
      const existingChatItems = (yield select())["WhatsAppWebReducer"]?.chatItems
      const selectedChatItemIndex = (yield select())["WhatsAppWebReducer"]
        ?.selectedChatItem

      if (selectedChatItemIndex !== -1 && existingChatItems?.length > 0) {
        existingChatItems.splice(selectedChatItemIndex, 1, {
          ...existingChatItems[selectedChatItemIndex],
          enableChat: asBoolean(response?.data?.enableChat) || false,
        })

        updatedChatItems = existingChatItems
      }
    } else {
      alertMessageData = {
        status: false,
        message: "Unable to fetch chat enabling status",
      }
    }
  } catch (err) {
    console.error("_getEnableChatDetails_catch", err)
    alertMessageData = {
      status: false,
      message: "Failed to fetch chat enabling status",
    }
  }

  yield put(actions.getEnableChatDetailsResponse(updatedChatItems, alertMessageData))
}
function* getEnableChatDetailsWatcher() {
  yield takeLatest(types.GET_ENABLE_CHAT_DETAILS_REQUEST, getEnableChatDetails)
}

// To get all the List and Reply Buttons Request and Response Data
function* getAllListAndReplyTemplatesWatcher() {
  yield takeLatest(
    types.GET_LIST_AND_REPLAY_MESSAGES_REQUEST,
    getAllListAndReplyTemplatesRequest
  )
}
function* getAllListAndReplyTemplatesRequest(action) {
  let listAndReplyMessagesData = []
  let alertMessagesData
  try {
    const cin = (yield select())["loginReducer"]?.sessionStateData?.ciNo
    let req = {
      cin: cin,
      system_tag: "User",
      templateId: 0,
      template_category: action.payload, //'List', "Reply Button"
    }
    const response = yield call(Post, `${urls.GET_All_WHATS_APP_TEMPLATES}`, req)
    if (response && response.status) {
      listAndReplyMessagesData = response.data.templates
    } else {
      alertMessagesData = {
        status: response.status ?? false,
        message: response?.message || "Some thing went worng",
      }
    }
  } catch (err) {
    alertMessagesData = {
      status: false,
      message: err?.message || "Failed to fetch List templates",
    }
  }
  yield put(
    actions.getAllListAndReplyMessageTemplateResponse(
      listAndReplyMessagesData,
      alertMessagesData
    )
  )
}

function* fetchMoreConversationsWatcher() {
  yield takeLatest(types.FETCH_MORE_CONVERSATIONS_REQUEST, fetchMoreConversations)
}
function* fetchMoreConversations(action) {
  let fetchedConversations, updatedConversationPaginationData, alertMessageData

  try {
    const phoneNumber = action.payload
    const conversationPaginationData = (yield select())?.["WhatsAppWebReducer"]
      ?.conversationPaginationData
    const loginState = (yield select())["loginReducer"]
    const cin = loginState?.sessionStateData?.ciNo
    const { wba_phone_no: masterPhoneNumber } =
      loginState?.wbaDetails?.wba_phone_numbers?.[0] || {}
    const requestingPageNo = (conversationPaginationData?.page || 0) + 1

    const requestObj = {
      cin: cin,
      masterPhoneNo: masterPhoneNumber,
      mobileNo: phoneNumber,
      pageNo: requestingPageNo,
      per_page: CONVERSATIONS_PER_SCROLL,
    }

    if (
      conversationPaginationData?.total_Pages >= requestingPageNo &&
      conversationPaginationData?.page !== requestingPageNo
    ) {
      const response = yield call(
        Post,
        urls.GET_WHATSAPP_CHAT_CONVERSATION,
        requestObj
      )
      console.log("_fetchMoreConversations_response", response.data)

      if (response?.data?.status) {
        fetchedConversations = orderBy(
          response?.data?.data || [],
          "updatedTs",
          "asc"
        )
        updatedConversationPaginationData = {
          phoneNumber: response?.data?.phoneNumber,
          // page: requestingPageNo,
          page: response?.data?.page,
          total_Pages: response?.data?.total_Pages,
          total_records: response?.data?.total_records,
        }
        // totalConversations = response?.data?.total_records;
      } else {
        alertMessageData = {
          status: response?.data?.status ?? false,
          message:
            response?.data?.message || "Unable to fetch further conversations",
        }
      }
    }
  } catch (err) {
    alertMessageData = {
      status: false,
      message: err?.message || "Failed to fetch further conversations",
    }
  }

  yield put(
    actions.fetchMoreConversationsResponse(
      fetchedConversations,
      updatedConversationPaginationData,
      alertMessageData
    )
  )
}

function* fetchWhatsappScheduledMessages() {
  let whatsappScheduledMessages, alertMessageData

  try {
    const loginState = (yield select())["loginReducer"]
    const cin = loginState?.sessionStateData?.ciNo
    const { wba_phone_no: masterPhoneNumber } =
      loginState?.wbaDetails?.wba_phone_numbers?.[0] || {}

    const response = yield call(
      Post,
      `${urls.GET_WHATSAPP_SCHEDULED_MESSAGES}?cin=${cin}&mobileNo=${masterPhoneNumber}`
    )
    console.log("_fetchWhatsappScheduledMessages_response", response.data)

    if (response?.data?.status) {
      whatsappScheduledMessages = response?.data?.data || []
    } else {
      alertMessageData = {
        status: false,
        message: "Unable to fetch scheduled whatsapp messages",
      }
    }
  } catch (err) {
    console.error(`_fetchWhatsappScheduledMessages_catch`, err)
    alertMessageData = {
      status: false,
      message: "Failed to fetch scheduled whatsapp messages",
    }
  }

  yield put(
    actions.getWhatsappScheduledMessagesResponse(
      whatsappScheduledMessages,
      alertMessageData
    )
  )
}
function* fetchWhatsappScheduledMessagesWatcher() {
  yield takeLatest(
    types.GET_WHATSAPP_SCHEDULED_MESSAGES_REQUEST,
    fetchWhatsappScheduledMessages
  )
}

function* createOrUpdateScheduledMessages(action) {
  let updatedScheduledMessages, alertMessageData

  try {
    const loginState = (yield select())["loginReducer"]
    const scheduledMessages =
      (yield select())["WhatsAppWebReducer"]?.scheduledMessages || []
    const cin = loginState?.sessionStateData?.ciNo
    const { wba_phone_no: masterPhoneNo } =
      loginState?.wbaDetails?.wba_phone_numbers?.[0] || {}

    const requestObj = {
      ...action.payload,
      cin,
      masterPhoneNo,
      messageType: "template",
      repeatEvery: "",
      type: "scheduled",
    }

    const response = yield call(Post, urls.SCHEDULE_WHATSAPP_MESSAGE, requestObj)
    console.log("_fetchWhatsappScheduledMessages_response", { requestObj, response })

    if (response?.data?.status) {
      updatedScheduledMessages = [requestObj, ...scheduledMessages]
    } else {
      alertMessageData = {
        status: false,
        message: "Unable to scheduled the whatsapp message",
      }
    }
  } catch (err) {
    console.error("_createOrUpdateScheduledMessages_catch", err)
    alertMessageData = {
      status: false,
      message: "Failed to scheduled the whatsapp message",
    }
  }

  yield put(
    actions.createOrUpdateWhatsappScheduledMessageResponse(
      updatedScheduledMessages,
      alertMessageData
    )
  )
}
function* createOrUpdateScheduledMessagesWatcher() {
  yield takeLatest(
    types.CREATE_OR_UPDATE_WHATSAPP_SCHEDULED_MESSAGE_REQUEST,
    createOrUpdateScheduledMessages
  )
}
function* getAgentsRequestWatcher() {
  yield takeLatest(types.GET_AGENTS_REQUEST, getAgentsRequests)
}
function* getAgentsRequests(action) {
  const loginState = (yield select())["loginReducer"]
  const cin = loginState?.sessionStateData?.ciNo
  let agentsDetails
  let alertMessageData

  try {
    const reqData = {
      agentStatus: "Active",
      cin: cin,
      role: "",
    }
    const response = yield call(Post, urls.GET_ADMIN_AGENTS, reqData)
    if (response && response?.data?.status) {
      agentsDetails = response?.data?.agents
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable To Get Agent Detials",
      }
    }
  } catch (error) {
    console.log("getAdminAgent_error", error)
    alertMessageData = {
      status: false,
      message: error?.data?.message || "Failed To get Agent Details",
    }
  }
  yield put(actions.getAgentsDataResponse(agentsDetails, alertMessageData))
}
function* getLabelsBasedOnTicketIdWatcher() {
  yield takeLatest(
    types.GET_LABELS_BASED_ON_TICKET_ID_REQUEST,
    getLablesBasedOnTicketId
  )
}
function* getLablesBasedOnTicketId(action) {
  const loginState = (yield select())["loginReducer"]
  console.log("yes we are making api call==============>")
  const cin = loginState?.sessionStateData?.ciNo
  const { wba_phone_no: masterPhoneNumber } =
    loginState?.wbaDetails?.wba_phone_numbers?.[0] || {}
  console.log("get_lables_based_on_token_id", { action, cin, loginState })
  let labels
  let alertMessageData

  try {
    const reqData = {
      agentId: 0,
      cin: cin,
      masterPhoneNo: masterPhoneNumber,
      searchByNumber: action?.payload?.phoneNumber,
      status: "Active",
    }
    const response = yield call(Post, urls.GET_WHATSAPP_CHAT_LIST, reqData)
    if (response && response?.data?.status) {
      labels = response?.data?.data?.[0]?.labels
    } else {
      alertMessageData = {
        status: false,
        message: response?.message || "Unable To Get Agent Detials",
      }
    }
  } catch (error) {
    console.log("getAdminAgent_error", error)
    alertMessageData = {
      status: false,
      message: error?.data?.message || "Failed To get Agent Details",
    }
  }
  console.log("get_labels_bsed_onTicketId", labels)
  yield put(actions.getLablesBasedOnTicketIDResponse(labels, alertMessageData))
}
export default function* whatsappWebSaga() {
  yield all([
    fork(getChatItemsWatcher),
    fork(getConversationsWatcher),
    fork(getEnableChatDetailsWatcher),
    fork(getAllListAndReplyTemplatesWatcher),
    fork(fetchMoreConversationsWatcher),
    fork(fetchWhatsappScheduledMessagesWatcher),
    fork(createOrUpdateScheduledMessagesWatcher),
    fork(getMarketOptOutMobileNumbersWatcher),
    fork(getAgentsRequestWatcher),
    fork(getLabelsBasedOnTicketIdWatcher),
  ])
}
