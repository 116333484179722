export const SELECTION_STATE_FOR_COMPONENTS = "SELECTION_STATE_FOR_COMPONENTS"
export const SHOW_QUICK_REPLY_MODAL = "SHOW_QUICK_REPLY_MODAL"
export const CLOSE_QUICK_REPLY_MODAL = "CLOSE_QUICK_REPLY_MODAL"

export const GET_CHAT_ITEMS_REQUEST = "GET_CHAT_ITEMS_REQUEST"
export const GET_CHAT_ITEMS_RESPONSE = "GET_CHAT_ITEMS_RESPONSE"

export const SET_WHATSAPP_WEB_STATE_BY_KEY = "SET_WHATSAPP_WEB_STATE_BY_KEY"

export const GET_CONVERSATIONS_REQUEST = "GET_CONVERSATIONS_REQUEST"
export const GET_CONVERSATIONS_RESPONSE = "GET_CONVERSATIONS_RESPONSE"

export const GET_ENABLE_CHAT_DETAILS_REQUEST = "GET_ENABLE_CHAT_DETAILS_REQUEST"
export const GET_ENABLE_CHAT_DETAILS_RESPONSE = "GET_ENABLE_CHAT_DETAILS_RESPONSE"

export const SOCKET_MESSAGE_RECEIVED = "SOCKET_MESSAGE_RECEIVED"
export const SOCKET_MESSAGE_STATUS_RECEIVED = "SOCKET_MESSAGE_STATUS_RECEIVED"
export const SOCKET_MESSAGE_MAPPING_RECEIVED = "SOCKET_MESSAGE_MAPPING_RECEIVED"

export const GET_LIST_AND_REPLAY_MESSAGES_REQUEST =
  "GET_LIST_AND_REPLAY_MESSAGES_REQUEST"
export const GET_LIST_AND_REPLAY_MESSAGES_RESPONSE =
  "GET_LIST_AND_REPLAY_MESSAGES_RESPONSE"

export const FETCH_MORE_CONVERSATIONS_REQUEST = "FETCH_MORE_CONVERSATIONS_REQUEST"
export const FETCH_MORE_CONVERSATIONS_RESPONSE = "FETCH_MORE_CONVERSATIONS_RESPONSE"

export const INITIATE_NEW_CHAT = "INITIATE_NEW_CHAT"

export const UPDATE_CONVERSATION_WITH_EMITTED_MESSAGE =
  "UPDATE_CONVERSATION_WITH_EMITTED_MESSAGE"

export const GET_WHATSAPP_SCHEDULED_MESSAGES_REQUEST =
  "GET_WHATSAPP_SCHEDULED_MESSAGES_REQUEST"
export const GET_WHATSAPP_SCHEDULED_MESSAGES_RESPONSE =
  "GET_WHATSAPP_SCHEDULED_MESSAGES_RESPONSE"

export const CREATE_OR_UPDATE_WHATSAPP_SCHEDULED_MESSAGE_REQUEST =
  "CREATE_OR_UPDATE_WHATSAPP_SCHEDULED_MESSAGE_REQUEST"
export const CREATE_OR_UPDATE_WHATSAPP_SCHEDULED_MESSAGE_RESPONSE =
  "CREATE_OR_UPDATE_WHATSAPP_SCHEDULED_MESSAGE_RESPONSE"

export const GET_MARKET_OPT_OUT_MOBILE_NUMBERS_REQUEST =
  "GET_MARKET_OPT_OUT_MOBILE_NUMBERS_REQUEST"
export const GET_MARKET_OPT_OUT_MOBILE_NUMBERS_RESPONSE =
  "GET_MARKET_OPT_OUT_MOBILE_NUMBERS_RESPONSE"

export const SHOW_CHART_OPTIONS_MENU_REQUEST = "SHOW_CHART_OPTIONS_MENU_REQUEST"
export const GET_AGENTS_REQUEST = "GET_AGENTS_REQUEST"
export const GET_AGENTS_RESPONSE = "GET_AGENTS_RESPONSE"
export const GET_LABELS_BASED_ON_TICKET_ID_REQUEST =
  "GET_LABELS_BASED_ON_TICKET_ID_REQUEST"
export const GET_LABELS_BASED_ON_TICKET_ID_RESPONSE =
  "GET_LABELS_BASED_ON_TICKET_ID_RESPONSE"
export const SHOW_MODEL_FOR_CLOSED_STATUS_REQUEST =
  "SHOW_MODEL_FOR_CLOSED_STATUS_REQUEST"

export const SET_TICKET_STATUS_FOR_MESSAGE_RECEIVED_CONTACTS_REQUEST =
  "SET_TICKET_STATUS_FOR_MESSAGE_RECEIVED_CONTACTS_REQUEST"
