import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import hardSet from "redux-persist/lib/stateReconciler/hardSet"
import { encryptTransform } from "redux-persist-transform-encrypt"

import rootReducer from "./reducers"
import rootSaga from "./sagas"

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers =
  (process.env.NODE_ENV !== "production" &&
    typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const encryptor = encryptTransform({
  secretKey: "Gr3@tW@t3rS@lt@1B2c3D4e5F6g7H8",
  onError: function (error) {
    // Handle the error.
  },
})

const persistedReducer = persistReducer(
  {
    key: "root",
    storage,
    stateReconciler: hardSet,
    transforms: [encryptor],
    whitelist: ["loginReducer"],
    blacklist: [""],
  },
  rootReducer
)

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)
export let persistStoreData = () => {
  let persistor = persistStore(store)
  return { store, persistor }
}
sagaMiddleware.run(rootSaga)

// Initialize a variable to hold the previous state
let prevState = store.getState()

// Log the state changes, along with the action
store.subscribe(() => {
  const currentState = store.getState()
  const action = store.dispatch // You can log the last dispatched action if you want

  console.log("Previous State:", prevState)
  console.log("Current State:", currentState)
  console.log("Last Dispatched Action:", action)

  // Update prevState for the next change
  prevState = currentState
})

export default store
