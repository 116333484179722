export const OBJECT_STATE = {
  NEW: "NEW",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
}

export const STATUS_IMAGES = "STATUS_IMAGES"
export const PROFILE_ICONS = "PROFILE_ICONS"

export const ACTION_TYPES = {
  SELECT: "SELECT",
  ADD: "ADD",
  EDIT: "EDIT",
  DUPLICATE_CAMPAIGN: "DUPLICATE_CAMPAIGN",
  SHOW_MODEL: "SHOW_MODEL",
  SHOW_MODEL_FOR_SAMPLE: "SHOW_MODEL_FOR_SAMPLE",
  EXISTING_TEMPLATES: "EXISTING_TEMPLATES",
  SELECT_CONTACTS_CAMPAIGN_MODAL: "SELECT_CONTACTS_CAMPAIGN_MODAL",
  DELETE: "DELETE",
  VIEW: "VIEW",
  ADD_REPLY_BUTTON: "ADD_REPLY_BUTTON",
  EDIT_REPLY_BUTTON: "EDIT_REPLY_BUTTON",
  ONE_TIME_MESSAGE_RULE: "ONE_TIME_MESSAGE_RULE",
  FLOW_MESSAGE_RULE: "FLOW_MESSAGE_RULE",
  KEYWORD_VERIFICATION_IN_MESSAGE_RULES: "KEYWORD_VERIFICATION_IN_MESSAGE_RULES",
}

export const TABLE_DATA_PAGE_SIZE = 5

export const MAX_DYNAMIC_VARIABLES_FOR_EMAIL_TEMPLATES = 5

export const templateCategoryOptionsMap = {
  PROMOTIONS: "PROMOTIONS",
  MARKETING: "MARKETING",
}
export const templateCategoryOptions = [
  { label: "PROMOTIONS", value: templateCategoryOptionsMap.PROMOTIONS },
  { label: "MARKETING", value: templateCategoryOptionsMap.MARKETING },
]

export const STATUS_OPTIONS_MAP = {
  APPROVED: "APPROVED",
  IN_PROGRESS: "inprogress",
  IN_REVIEW: "In Review",
  NOT_STARTED: "NOT STARTED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  PENDING_DELETION: "PENDING_DELETION",
}

export const statusOptions = [
  { label: "Select", value: "Select" },
  { label: "Approved", value: STATUS_OPTIONS_MAP.APPROVED },
  // { label: 'IN PROGRESS', value: STATUS_OPTIONS_MAP.IN_PROGRESS },
  { label: "In Review", value: STATUS_OPTIONS_MAP.IN_REVIEW },
  { label: "Not Started", value: STATUS_OPTIONS_MAP.NOT_STARTED },
  // { label: 'PENDING', value: STATUS_OPTIONS_MAP.PENDING },
  { label: "Rejected", value: STATUS_OPTIONS_MAP.REJECTED },
  // { label: "PENDING_DELETION", value: STATUS_OPTIONS_MAP.PENDING_DELETION }
]
export const templateStatus = {
  PENDING: "PENDING",
  NOT_STARTED: "NOT STARTED",
  SENT: "Sent",
  REJECTED: "REJECTED",
  INPROGESS: "In progress",
  Started: "Started",
  APPROVED: "APPROVED",
  INREVIEW: "IN REVIEW",
  PENDING_DELETION: "PENDING_DELETION",
}
export const businsessType = [
  { label: "Automotive", value: 1 },
  { label: "Beauty, Spa and Salon", value: 2 },
  { label: "Clothing and Apparel", value: 3 },
  { label: "Education", value: 4 },
  { label: "Entertainment", value: 5 },
  { label: "Event Planning and Service", value: 6 },
  { label: "Finance and Banking", value: 7 },
  { label: "Food and Grocery", value: 8 },
  { label: "Public Service", value: 9 },
  { label: "Hotel and Lodging", value: 10 },
  { label: "Medical and Health", value: 11 },
  { label: "Non - profit", value: 12 },
  { label: "Professional Services", value: 13 },
  { label: "Shopping and Retail", value: 14 },
  { label: "Travel and Transportation", value: 15 },
  { label: "Restaurant", value: 16 },
  { label: "Other", value: 17 },
]
// matches {{1
export const matchTemplateVariableCountRegEx = new RegExp(
  /((?<!\w){{\w+)|((?<!\w ){{\w+)/gm
)

// matches {{1}}
export const matchEntireTemplateVariableCountRegEx = new RegExp(
  /((?<!\w){{[1-5]}}+)|((?<!\w ){{[1-5]}}+)/gm
)
// export const matchEntireTemplateVariableCountRegEx = new RegExp(/((?<!\w){{\w+}}+)|((?<!\w ){{\w+}}+)/gm);

export const reactQuillToolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ["clean"], // remove formatting button
]

export const ENDING_TAG_COUNT = 4

export const ACCEPTED_IMAGE_FILE_TYPES = ["jpg", "jpeg", "png", "svg", "gif"]
export const ACCEPTED_DOCUMENT_FILE_TYPES = [
  "doc",
  "docx",
  "xls",
  "xlsx",
  "csv",
  "ppt",
  "pptx",
  "pdf",
  "rtf",
]
export const ACCEPTED_VIDEO_FILE_TYPES = [
  "mp4",
  "wmv",
  "3gp",
  "flv",
  "mov",
  "m4v",
  "mpg",
  "avi",
]

export const ACCEPTED_FILE_TYPES = [
  ...ACCEPTED_DOCUMENT_FILE_TYPES,
  ...ACCEPTED_IMAGE_FILE_TYPES,
  ...ACCEPTED_VIDEO_FILE_TYPES,
  "mp3",
  "wav",
  "aac",
  "aif",
  "ogg",
  "zip",
  "rar",
]

export const MAX_ATTACHMENTS_PER_TEMPLATE = 5

export const MAX_FILE_SIZE_IN_MB = 20

export const EMAIL_TEMPLATE_NAME_MAX_LENGTH = 150

export const getTemplateVariableNextCount = str => {
  const matches = str.match(matchTemplateVariableCountRegEx)
  if (matches && matches.length) {
    return Math.max(...matches.map(matchStr => Number(matchStr.substr(-1)))) + 1
  }
  return 1
}

// Campaign Management
export const CAMPAIGN_CHANNEL_MAP = {
  whatsapp: "Whatsapp",
  email: "Email",
}
export const CAMPAIGN_CHANNEL_OPTIONS = [
  { label: "Whatsapp", value: CAMPAIGN_CHANNEL_MAP.whatsapp },
  // { label: 'Email', value: CAMPAIGN_CHANNEL_MAP.email },
]

export const CAMPAIGN_STATUS_MAP = {
  started: "In Progress",
  notstarted: "Not Started",
  completed: "Completed",
  cron_completed: "COMPLETED",
  draft: "Draft",
}
export const CAMPAIGN_STATUS_OPTIONS = [
  { label: "In Progress", value: CAMPAIGN_STATUS_MAP.started },
  { label: "Not Started", value: CAMPAIGN_STATUS_MAP.notstarted },
  { label: "Completed", value: CAMPAIGN_STATUS_MAP.cron_completed },
  { label: "Draft", value: CAMPAIGN_STATUS_MAP.draft },
]

export const CAMPAIGN_TYPE_MAP = {
  instant: "Instant",
  scheduled: "Scheduled",
  recurring: "Recurring",
}
export const CAMPAIGN_TYPE_OPTIONS = [
  { label: "Instant", value: CAMPAIGN_TYPE_MAP.instant },
  { label: "Scheduled", value: CAMPAIGN_TYPE_MAP.scheduled },
  // { label: 'Recurring', value: CAMPAIGN_TYPE_MAP.recurring },
]

export const DAY_NAME_BY_NUMBER_MAP = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  YESTERDAY: "Yesterday",
}

export const DAY_NAMES_MAP = {
  SUNDAY: "sunday",
  MONDAY: "monday",
  TUESDAY: "tuesday",
  WEDNESDAY: "wednesday",
  THURSDAY: "thursday",
  FRIDAY: "friday",
  SATURDAY: "saturday",
}
export const DAY_NAMES_OF_WEEK = [
  { label: "Sunday", value: DAY_NAMES_MAP.SUNDAY },
  { label: "Monday", value: DAY_NAMES_MAP.MONDAY },
  { label: "Tuesday", value: DAY_NAMES_MAP.TUESDAY },
  { label: "Wednesday", value: DAY_NAMES_MAP.WEDNESDAY },
  { label: "Thursday", value: DAY_NAMES_MAP.THURSDAY },
  { label: "Friday", value: DAY_NAMES_MAP.FRIDAY },
  { label: "Saturday", value: DAY_NAMES_MAP.SATURDAY },
]
export const DAYS_SORTER = {
  [DAY_NAMES_MAP.SUNDAY]: 0,
  [DAY_NAMES_MAP.MONDAY]: 1,
  [DAY_NAMES_MAP.TUESDAY]: 2,
  [DAY_NAMES_MAP.WEDNESDAY]: 3,
  [DAY_NAMES_MAP.THURSDAY]: 4,
  [DAY_NAMES_MAP.FRIDAY]: 5,
  [DAY_NAMES_MAP.SATURDAY]: 6,
}

export const CAMPAIGN_FREQUENCY_OPTIONS = [
  ...DAY_NAMES_OF_WEEK,
  { label: "Custom", value: "custom" },
]

export const CAMPAIGN_SIGNAL_MAP = {
  ACTIVE: "Active",
  INACTIVE: "InActive",
}
export const CAMPAIGN_SIGNAL_OPTIONS = [
  { label: "Active", value: CAMPAIGN_SIGNAL_MAP.ACTIVE },
  { label: "InActive", value: CAMPAIGN_SIGNAL_MAP.INACTIVE },
]

export const CAMPAIGN_CONTACTS_ATTRIBUTES_TYPE_OPTIONS_MAP = {
  TEXT: "attributeValue",
  DATE_TIME: "dateTime",
  CURRENCY: "currency",
  CUSTOM: "custom",
}
export const CAMPAIGN_CONTACTS_ATTRIBUTES_TYPE_OPTIONS = [
  { label: "Text", value: CAMPAIGN_CONTACTS_ATTRIBUTES_TYPE_OPTIONS_MAP.TEXT },
  {
    label: "Date And Time",
    value: CAMPAIGN_CONTACTS_ATTRIBUTES_TYPE_OPTIONS_MAP.DATE_TIME,
  },
  {
    label: "Currency",
    value: CAMPAIGN_CONTACTS_ATTRIBUTES_TYPE_OPTIONS_MAP.CURRENCY,
  },
]

export const CAMPAIGN_CONTACTS_ATTRIBUTE_TEXT_OPTIONS = [
  { label: "Name", value: "contactName" },
  { label: "Date of Birth", value: "dob" },
  { label: "Email Id", value: "emailId" },
  { label: "Mobile No", value: "contactNo" },
  { label: "Occupation", value: "occupation" },
  { label: "Job Title", value: "jobTitle" },
  { label: "Department", value: "department" },
  { label: "Category", value: "category" },
  { label: "Relation Officer", value: "relOfficer" },
  { label: "Plain Text", value: "custom" },
]

export const CAMPAIGN_CONTACTS_CURRENCY_OPTIONS = [
  {
    label: "Dirham (United Arab Emirates)",
    value: "AED",
  },
  {
    label: "Peso (Argentine)",
    value: "ARS",
  },
  {
    label: "Dollar (Australian)",
    value: "AUD",
  },
  {
    label: "Taka (Bangladeshi)",
    value: "BDT",
  },
  {
    label: "Lev (Bulgarian)",
    value: "BGN",
  },
  {
    label: "Dinar (Bahraini)",
    value: "BHD",
  },
  {
    label: "Dollar (Brunei)",
    value: "BND",
  },
  {
    label: "Boliviano (Bolivian)",
    value: "BOB",
  },
  {
    label: "Real (Brazilian)",
    value: "BRL",
  },
  {
    label: "Pula (Botswanan)",
    value: "BWP",
  },
  {
    label: "Ruble (Belarusian)",
    value: "BYN",
  },
  {
    label: "Dollar (Canadian)",
    value: "CAD",
  },
  {
    label: "Franc (Swiss)",
    value: "CHF",
  },
  {
    label: "Peso (Chilean)",
    value: "CLP",
  },
  {
    label: "Yuan (Chinese)",
    value: "CNY",
  },
  {
    label: "Peso (Colombian)",
    value: "COP",
  },
  {
    label: "Colón (Costa Rican)",
    value: "CRC",
  },
  {
    label: "Koruna (Czech)",
    value: "CZK",
  },
  {
    label: "Krone (Danish)",
    value: "DKK",
  },
  {
    label: "Peso (Dominican)",
    value: "DOP",
  },
  {
    label: "Dinar (Algerian)",
    value: "DZD",
  },
  {
    label: "Pound (Egyptian)",
    value: "EGP",
  },
  {
    label: "Dollar (Fijian)",
    value: "FJD",
  },
  {
    label: "Sterling (British Pound)",
    value: "GBP",
  },
  {
    label: "Euro (Euro)",
    value: "EUR",
  },
  {
    label: "Lari (Georgian)",
    value: "GEL",
  },
  {
    label: "Cedi (Ghanaian)",
    value: "GHS",
  },
  {
    label: "Dollar (Hong Kong)",
    value: "HKD",
  },
  {
    label: "Kuna (Croatian)",
    value: "HRK",
  },
  {
    label: "Forint (Hungarian)",
    value: "HUF",
  },
  {
    label: "Rupiah (Indonesian)",
    value: "IDR",
  },
  {
    label: "New Sheqel (Israeli)",
    value: "ILS",
  },
  {
    label: "Rupee (Indian)",
    value: "INR",
  },
  {
    label: "Dinar (Iraqi)",
    value: "IQD",
  },
  {
    label: "Dinar (Jordanian)",
    value: "JOD",
  },
  {
    label: "Yen (Japanese)",
    value: "JPY",
  },
  {
    label: "Shilling (Kenyan)",
    value: "KES",
  },
  {
    label: "Won (South Korean)",
    value: "KRW",
  },
  {
    label: "Dinar (Kuwaiti)",
    value: "KWD",
  },
  {
    label: "Tenge (Kazakhstani)",
    value: "KZT",
  },
  {
    label: "Pound (Lebanese)",
    value: "LBP",
  },
  {
    label: "Rupee (Sri Lankan)",
    value: "LKR",
  },
  {
    label: "Litas (Lithuanian)",
    value: "LTL",
  },
  {
    label: "Dirham (Moroccan)",
    value: "MAD",
  },
  {
    label: "Kyat (Myanma)",
    value: "MMK",
  },
  {
    label: "Pataca (Macanese)",
    value: "MOP",
  },
  {
    label: "Rupee (Mauritian)",
    value: "MUR",
  },
  {
    label: "Peso (Mexican)",
    value: "MXN",
  },
  {
    label: "Ringgit (Malaysian)",
    value: "MYR",
  },
  {
    label: "Dollar (Namibian)",
    value: "NAD",
  },
  {
    label: "Naira (Nigerian)",
    value: "NGN",
  },
  {
    label: "Córdoba (Nicaraguan)",
    value: "NIO",
  },
  {
    label: "Krone (Norwegian)",
    value: "NOK",
  },
  {
    label: "Rupee (Nepalese)",
    value: "NPR",
  },
  {
    label: "Dollar (New Zealand)",
    value: "NZD",
  },
  {
    label: "Rial (Omani)",
    value: "OMR",
  },
  {
    label: "Sol (Peruvian Nuevo)",
    value: "PEN",
  },
  {
    label: "Peso (Philippine)",
    value: "PHP",
  },
  {
    label: "Rupee (Pakistani)",
    value: "PKR",
  },
  {
    label: "Zloty (Polish)",
    value: "PLN",
  },
  {
    label: "Guarani (Paraguayan)",
    value: "PYG",
  },
  {
    label: "Rial (Qatari)",
    value: "QAR",
  },
  {
    label: "Leu (Romanian)",
    value: "RON",
  },
  {
    label: "Dinar (Serbian)",
    value: "RSD",
  },
  {
    label: "Ruble (Russian)",
    value: "RUB",
  },
  {
    label: "Riyal (Saudi)",
    value: "SAR",
  },
  {
    label: "Krona (Swedish)",
    value: "SEK",
  },
  {
    label: "Dollar (Singapore)",
    value: "SGD",
  },
  {
    label: "Colón (Salvadoran)",
    value: "SVC",
  },
  {
    label: "Baht (Thai)",
    value: "THB",
  },
  {
    label: "Dinar (Tunisian)",
    value: "TND",
  },
  {
    label: "Lira (Turkish)",
    value: "TRY",
  },
  {
    label: "Dollar (New Taiwan)",
    value: "TWD",
  },
  {
    label: "Shilling (Tanzanian)",
    value: "TZS",
  },
  {
    label: "Hryvnia (Ukrainian)",
    value: "UAH",
  },
  {
    label: "Shilling (Ugandan)",
    value: "UGX",
  },
  {
    label: "Dollar (US)",
    value: "USD",
  },
  {
    label: "Peso (Uruguayan)",
    value: "UYU",
  },
  {
    label: "Som (Uzbekistan)",
    value: "UZS",
  },
  {
    label: "Bolívar (Venezuelan)",
    value: "VEF",
  },
  {
    label: "Bolívar (Venezuelan)",
    value: "VES",
  },
  {
    label: "Dong (Vietnamese)",
    value: "VND",
  },
  {
    label: "BCEAO (CFA Franc)",
    value: "UYU",
  },
  {
    label: "Rand (South African)",
    value: "ZAR",
  },
]

export const CODE_VALUES_MAP = {
  CATEGORY: "category",
  STATUS: "employeeStatus",
  CUSTOMER_TYPE: "customerType",
  OCCUPATION: "occupationalGroups",
  STAFF_FLAG: "staffIndicator",
}

export const CAMPAIGN_MENU_OPTIONS = {
  VIEW_CAMPAIGN_CONTACTS: "VIEW_CAMPAIGN_CONTACTS",
  EDIT_CAMPAIGN: "EDIT_CAMPAIGN",
  DELETE_CAMPAIGN: "DELETE_CAMPAIGN",
  DUPLICATE_CAMPAIGN: "DUPLICATE_CAMPAIGN",
  VIEW_INSIGHTS: "VIEW_INSIGHTS",
}

export const CAMPAIGN_MENU_ACTION_TYPES = {
  VIEW_CAMPAIGN_CONTACTS: CAMPAIGN_MENU_OPTIONS.VIEW_CAMPAIGN_CONTACTS,
  EDIT_CAMPAIGN: CAMPAIGN_MENU_OPTIONS.EDIT_CAMPAIGN,
  DELETE_CAMPAIGN: CAMPAIGN_MENU_OPTIONS.DELETE_CAMPAIGN,
  DUPLICATE_CAMPAIGN: CAMPAIGN_MENU_OPTIONS.DUPLICATE_CAMPAIGN,
  VIEW_INSIGHTS: CAMPAIGN_MENU_OPTIONS.VIEW_INSIGHTS,
}

export const CAMPAIGN_CONFIRMATION_MODAL_TYPES = {
  START: "START",
  ENABLE: "ENABLE",
  DISABLE: "DISABLE",
  DELETE: "DELETE",
  CREATE_CAMPAIGN: "CREATE",
  UPDATE_CAMPAIGN: "UPDATE",
}

// TODO: (api_integration) remove this after integrating the Master emails API is ready to integrate.
export const STATIC_MASTER_EMAILS = [
  { label: "Manoj", value: "manoj@notifyy@gmail.com" },
]

export const CAMPAIGN_TIMEZONE_OPTIONS = [
  { label: "Indian standard time", value: "IST" },
]

// Campaign recurring constants
export const ZERO_TO_HUNDRED_DAYS = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
  {
    label: 6,
    value: 6,
  },
  {
    label: 7,
    value: 7,
  },
  {
    label: 8,
    value: 8,
  },
  {
    label: 9,
    value: 9,
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 11,
    value: 11,
  },
  {
    label: 12,
    value: 12,
  },
  {
    label: 13,
    value: 13,
  },
  {
    label: 14,
    value: 14,
  },
  {
    label: 15,
    value: 15,
  },
  {
    label: 16,
    value: 16,
  },
  {
    label: 17,
    value: 17,
  },
  {
    label: 18,
    value: 18,
  },
  {
    label: 19,
    value: 19,
  },
  {
    label: 20,
    value: 20,
  },
  {
    label: 21,
    value: 21,
  },
  {
    label: 22,
    value: 22,
  },
  {
    label: 23,
    value: 23,
  },
  {
    label: 24,
    value: 24,
  },
  {
    label: 25,
    value: 25,
  },
  {
    label: 26,
    value: 26,
  },
  {
    label: 27,
    value: 27,
  },
  {
    label: 28,
    value: 28,
  },
  {
    label: 29,
    value: 29,
  },
  {
    label: 30,
    value: 30,
  },
  {
    label: 31,
    value: 31,
  },
  {
    label: 32,
    value: 32,
  },
  {
    label: 33,
    value: 33,
  },
  {
    label: 34,
    value: 34,
  },
  {
    label: 35,
    value: 35,
  },
  {
    label: 36,
    value: 36,
  },
  {
    label: 37,
    value: 37,
  },
  {
    label: 38,
    value: 38,
  },
  {
    label: 39,
    value: 39,
  },
  {
    label: 40,
    value: 40,
  },
  {
    label: 41,
    value: 41,
  },
  {
    label: 42,
    value: 42,
  },
  {
    label: 43,
    value: 43,
  },
  {
    label: 44,
    value: 44,
  },
  {
    label: 45,
    value: 45,
  },
  {
    label: 46,
    value: 46,
  },
  {
    label: 47,
    value: 47,
  },
  {
    label: 48,
    value: 48,
  },
  {
    label: 49,
    value: 49,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 51,
    value: 51,
  },
  {
    label: 52,
    value: 52,
  },
  {
    label: 53,
    value: 53,
  },
  {
    label: 54,
    value: 54,
  },
  {
    label: 55,
    value: 55,
  },
  {
    label: 56,
    value: 56,
  },
  {
    label: 57,
    value: 57,
  },
  {
    label: 58,
    value: 58,
  },
  {
    label: 59,
    value: 59,
  },
  {
    label: 60,
    value: 60,
  },
  {
    label: 61,
    value: 61,
  },
  {
    label: 62,
    value: 62,
  },
  {
    label: 63,
    value: 63,
  },
  {
    label: 64,
    value: 64,
  },
  {
    label: 65,
    value: 65,
  },
  {
    label: 66,
    value: 66,
  },
  {
    label: 67,
    value: 67,
  },
  {
    label: 68,
    value: 68,
  },
  {
    label: 69,
    value: 69,
  },
  {
    label: 70,
    value: 70,
  },
  {
    label: 71,
    value: 71,
  },
  {
    label: 72,
    value: 72,
  },
  {
    label: 73,
    value: 73,
  },
  {
    label: 74,
    value: 74,
  },
  {
    label: 75,
    value: 75,
  },
  {
    label: 76,
    value: 76,
  },
  {
    label: 77,
    value: 77,
  },
  {
    label: 78,
    value: 78,
  },
  {
    label: 79,
    value: 79,
  },
  {
    label: 80,
    value: 80,
  },
  {
    label: 81,
    value: 81,
  },
  {
    label: 82,
    value: 82,
  },
  {
    label: 83,
    value: 83,
  },
  {
    label: 84,
    value: 84,
  },
  {
    label: 85,
    value: 85,
  },
  {
    label: 86,
    value: 86,
  },
  {
    label: 87,
    value: 87,
  },
  {
    label: 88,
    value: 88,
  },
  {
    label: 89,
    value: 89,
  },
  {
    label: 90,
    value: 90,
  },
  {
    label: 91,
    value: 91,
  },
  {
    label: 92,
    value: 92,
  },
  {
    label: 93,
    value: 93,
  },
  {
    label: 94,
    value: 94,
  },
  {
    label: 95,
    value: 95,
  },
  {
    label: 96,
    value: 96,
  },
  {
    label: 97,
    value: 97,
  },
  {
    label: 98,
    value: 98,
  },
  {
    label: 99,
    value: 99,
  },
]

export const POSSIBLE_MONTH_DAYS = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
  {
    label: 6,
    value: 6,
  },
  {
    label: 7,
    value: 7,
  },
  {
    label: 8,
    value: 8,
  },
  {
    label: 9,
    value: 9,
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 11,
    value: 11,
  },
  {
    label: 12,
    value: 12,
  },
  {
    label: 13,
    value: 13,
  },
  {
    label: 14,
    value: 14,
  },
  {
    label: 15,
    value: 15,
  },
  {
    label: 16,
    value: 16,
  },
  {
    label: 17,
    value: 17,
  },
  {
    label: 18,
    value: 18,
  },
  {
    label: 19,
    value: 19,
  },
  {
    label: 20,
    value: 20,
  },
  {
    label: 21,
    value: 21,
  },
  {
    label: 22,
    value: 22,
  },
  {
    label: 23,
    value: 23,
  },
  {
    label: 24,
    value: 24,
  },
  {
    label: 25,
    value: 25,
  },
  {
    label: 26,
    value: 26,
  },
  {
    label: 27,
    value: 27,
  },
  {
    label: 28,
    value: 28,
  },
  {
    label: 29,
    value: 29,
  },
  {
    label: 30,
    value: 30,
  },
  {
    label: 31,
    value: 31,
  },
]

export const FREQUENCY_MAP = {
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
  YEARLY: "yearly",
}
export const FREQUENCY_OPTIONS = [
  { label: "Daily", value: FREQUENCY_MAP.DAILY },
  { label: "Weekly", value: FREQUENCY_MAP.WEEKLY },
  { label: "Monthly", value: FREQUENCY_MAP.MONTHLY },
  { label: "Yearly", value: FREQUENCY_MAP.YEARLY },
]

export const STARTER_DAYS_BY_MONTH = [
  { label: "First Day", value: "first_day" },
  { label: "Second Day", value: "second_day" },
  { label: "Third Day", value: "third_day" },
  { label: "Fourth Day", value: "fourth_day" },
  { label: "Last Day", value: "last_day" },
]

export const MONTH_NAMES = [
  { label: "January", value: "january" },
  { label: "Febraury", value: "febraury" },
  { label: "March", value: "march" },
  { label: "April", value: "april" },
  { label: "May", value: "may" },
  { label: "June", value: "june" },
  { label: "July", value: "july" },
  { label: "August", value: "august" },
  { label: "September", value: "september" },
  { label: "October", value: "october" },
  { label: "November", value: "november" },
  { label: "December", value: "december" },
]

export const END_DATE_TYPES_MAP = {
  ON_THIS_DAY: "on_this_day",
  AFTER_THIS_DAY: "after_this_day",
  NO_END_DATE: "",
}
export const END_DATE_TYPES = [
  { label: "On this day", value: END_DATE_TYPES_MAP.ON_THIS_DAY },
  { label: "After this day", value: END_DATE_TYPES_MAP.AFTER_THIS_DAY },
  { label: "No end date", value: END_DATE_TYPES_MAP.NO_END_DATE },
]

export const conversation_direction = {
  BUSINESS_INITIATED: "BUSINESS_INITIATED",
  USER_INITIATED: "USER_INITIATED",
  MARKETING: "MARKETING",
  SERVICE: "SERVICE",
  UTILITY: "UTILITY",
  AUTENTICATION: "AUTENTICATION",
}

export const Labels = {
  EMAIL_ID: "Email Id",
  PASSWORD: "Password",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  EMAIL_ADDRESS: "Email Address",
  PHONE_NO: "Phone No",
  ENTER_PASSWORD: "*Enter password",
  RE_ENTER_PASSWORD: "Re-Enter password",
  BUSINESS_TYPE: "Business Type",
  BUSINESS_NAME: "Business Name",
}

export const Placeholders = {
  ENTER_EMAIL_ID: "Enter Email Id",
  ENTER_PASSWORD: "Enter Password",
  FIRST_NAME: "Enter First Name",
  LAST_NAME: "Enter Last Name",
  EMAIL_ADDRESS: "Enter Email Id",
  PHONE_NO: "Enter Mobile No",
  ENTER_PASSWORD: "Enter Your Password",
  RE_ENTER_PASSWORD: "Re-Enter Password",
  BUSINESS_TYPE: "Select Business Type",
  BUSINESS_NAME: "Enter your Business Name",
}

export const RandomText = {
  FORGOT_PASSWORD: "Forgot Password",
  LOGIN: "Login",
  SIGN_IN: "Sing in",
  RESET_PASSWORD: "Reset Your Password",
  RESET_PASSWORD_ENTER_MAIL:
    "To reset your password, enter your registered email address.",
  SEND_RESET_PASSWORD_MAIL: "Send Password Reset Email",
  LOGIN_HERE: "Login here",
  NEW_TO_NOTIFYY: "New to Notifyy?",
  CREATE_ACCOUNT: "Create Account",
  CREATE_AN_ACCOUNT: "Create an account",
  SCALE_UP_TEXT_IN_CREATE_ACCOUNT: "Scale Up your Business using",
  WHATSAPP: "Whatsapp",
  ALL_WHATSAPP_TOOLS_ONE_PLATFORM: "All WhatsApp Tools, One Platform",
  RandomText_POINTS: {
    1: "Notify all your customers in single click in whatsapp",
    2: "Send advanced WhatsApp template messages with three quick reply buttons",
    3: "Send advanced WhatsApp template messages with Call to action Buttons.",
    4: "Send Interactive Messages with a Menu of maximum 10 Options.",
    5: "Create 2000 WhatsApp QR codes and short links for digital doorstep for businesses",
    6: "Send Rich Media WhatsApp messages containing Call- to - Action Buttons",
    7: "Whatsapp tem inbox to view Customer replies.",
    8: "Measure your team's responsiveness using Conversation Analytics",
    9: "Coding less chat boat to Auto reply messages to your customers",
    10: "Whatsapp Web Button on your Website",
  },
  ALREADY_CUSTOMER: "Already a customer?",
  AGREE: "Agree",
  AGREE_TOU1: "Terms & Conditions",
  AND: "and",
  AGREE_TOU2: "Privacy Policy",
  ASTERISK: "*",
  HAVING_TROUBLE_LOGIN: "Having trouble logging in?",
  BACK_TO_LOGIN: "Back to Login",
  CONTACT_US: "Contact us",
  INCORRECT_USERNAME_OR_PASSWORD: "Incorrect username or password",
  VIEW: "VIEW",
  NO_DATA_FOUND: "No Data Found",
  ITEMS_PER_PAGE: "Items Per Page",
  RECORDS: "Total Records",
  COPY_RIGHT_TEXT: "© 2022 Notifyy. All rights reserved.",
}

export const conversation_type = {
  FREE_TIER: "FREE_TIER",
  PAID: "PAID",
}

export const MOMENT_DATE_FORMAT_TYPES = {
  "DD/MM/YYYY": "DD/MM/YYYY",
  "MM/DD/YYYY": "MM/DD/YYYY",
  "YYYY-MM-DD": "YYYY-MM-DD",
  "DD/MM/YYYY hh:mm": "DD/MM/YYYY hh:mm",
  "MM/DD/YYYY hh:mm": "MM/DD/YYYY hh:mm",
  "YYYY-MM-DD HH:mm:ss": "YYYY-MM-DD HH:mm:ss",
}

export const CAMPAIGN_ATTRIBUTE_TYPES = {
  TEXT: "text",
  CURRENCY: "currency",
  DATE_TIME: "date_time",
}

export const SIDE_NAV_LABELS = {
  DASHBOARD: "Dashboard",
  CONTACT_MANAGEMENT: "Contacts",
  BULK_UPLOAD_CONTACTS: "Bulk Upload Contacts",
  MANAGE_CONTACTS: "Manage Contacts",
  CAMPAIGN_MANAGEMENT: "Campaigns",
  TEMPLATES: "Templates",
  EMAIL_TEMPLATES: "Email Templates",
  WHATSAPP_TEMPLATES: "Whatsapp Templates",
  QRCODE_TEMPLATES: "QR Codes",
  INTERATIVE_TEMPLATES: "Interactive Messages",
  LOOKUP_CONFIG: "Lookup Config",
  PAYMENT_SUBSCRIPTION: "Subscription",
  // RULES:"Rules",
  // RULES_CONFIGURATION:"Rules",
  REACT_FLOWS: "Flows",
  RULES: "Rules",
  REPLY_CONTENT: "Reply Content",
}

export const CRON_JOB_OBJECT_STATE = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  PAUSE: "PAUSE",
  RESUME: "RESUME",
  DELETE: "DELETE",
}

export const BREADCRUM_LABELS = {
  PROJECTS: "Projects",
  LOOKUP_TYPES: "Lookup Types",
  LOOKUP_TYPE: "Lookup Type",
  LOOKUP_VALUES: "Lookup Values",
  CONTACTS: "Contacts",
  MANAGE_CONTACTS: "Manage Contacts",
}

export const LOOKUP_CONFIGURATIONS_LABELS = {
  LOOKUP_TYPES: "Lookup Types",
  LOOKUP_TYPES_SUB_HEADING:
    "All codes Types are provided default by the System. Please click on VIEW to see possible values.",
  LOOKUP_TABLE_HEADINGS: {
    TH1: "Lookup Type",
    TH2: "Lookup Description",
    TH3: "Status",
    TH4: "Created By",
    TH5: "Action",
  },
  LOOKUP_VALUES: {
    LOOKUP_TYPE: "Lookup Type",
    LOOKUP_TYPE_SUB_HEADING:
      "Below are the possible Values for the Selected lookup type",
    LOOKUP_VALUES_TABLE_HEADINGS: {
      TH1: "Lookup Value",
      TH2: "Lookup Value Description",
      TH3: "Lookup Display Value	",
      TH4: "Status",
      TH5: "Created By",
    },
  },
}

export const CAMPAIGN_SAMPLE_CONTACTS_EXCEL_HEADERS_MAP = {
  NAME: "Name",
  SEND_MESSAGE: "Send Message",
  PHONE_NUMBER: "Phone Number",
  EMAIL_ID: "Email Id",
}

export const FILE_TYPES_MAP = {
  IMAGE: "image",
  DOCUMENT: "document",
  VIDEO: "video",
}

export const CAMPAIGN_FEEDBACK_TYPE_OPTIONS = [
  { label: "Look and Feel", value: "Look and Feel" },
  { label: "Performance", value: "Performance" },
  { label: "Bugs", value: "Bugs" },
  { label: "Others", value: "Others" },
]

export const FEEDBACK_RATING_OPTIONS = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
]

export const CAMPAIGN_FREQUENCY_TITLE = "One Time"

export const MAX_CHARS_FOR_CAMPAIGN_NAME = 100

export const CONTACTS_LABELS = {
  CONTACT_MANAGEMENT: "Contact Management",
  CONTACT_MANAGEMENT_SUBHEADING:
    "Manage your customer contacts clearly here so that it will be useful for organising and tracking your customer Records.",
}

export const SCHEDULED_CAMPAIGN_SAFE_BUFFER_TIME_IN_MINS = 30

// Whatsapp web
export const CHAT_RECORDS_PER_SCROLL = 20

export const CONVERSATIONS_PER_SCROLL = 20

export const WHATSAPP_WEB_ACTION_TYPES = {
  SELECT: "SELECT",
  VIEW_PROFILE: "VIEW_PROFILE",
  VIEW_FEEDBACK: "VIEW_FEEDBACK",
  CREATE_CONTACT: "CREATE_CONTACT",
  VIEW_CONTACTS: "VIEW_CONTACTS",
  VIEW_MESSAGES: "VIEW_MESSAGES",
  SCHEDULED_MESSAGES: "SCHEDULED_MESSAGES",
  UPDATE_CONTACT: "UPDATE_CONTACT",
}

export const WHATSAPP_MESSAGE_CHANNEL_TYPES_MAP = {
  WEBHOOK: "Webhook",
  API: "API",
}

export const MESSAGE_READ_RECEIPTS_MAP = {
  READ: "read",
  UNREAD: "unread",
}

export const BACKEND_FILE_TYPES_ENUM = {
  IMAGE: "IMAGE",
  VIDEO: "VEDIO",
  DOCUMENT: "DOCUMENT",
}

export const WHATSAPP_MEDIA_TYPE_MAP = {
  TEXT: "text",
  IMAGE: "image",
  VIDEO: "video",
  DOCUMENT: "document",
  CONTACT: "contact",
  LIST_MESSAGE: "interactive",
  TEMPLATE: "template",
  REPLY_MESSAGE: "replyMessage",
  REPLY_CONTENT: "replyContent",
  MEDIA: "media",
  LOCATION: "location",
  REACTION: "reaction",
  INTERACTIVE: "interactive",
}

export const documentAcceptTypes =
  ".ppt,.pptx,.pdf,.rtf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"

export const WHATSAPP_MAX_FILE_SIZE_MAP = {
  DEFAULT: 100,
  IMAGE: 100,
  VIDEO: 200,
  DOCUMENT: 200,
}

export const CAMPAIGN_CONTACTS_FILTER_SEARCH_TERMS_MAP = {
  GLOBAL: "search",
  OCCUPATION: "occupation",
  JOB_TITLE: "jobTitle",
  STAFF_FLAG: "staffFlag",
  CUSTOMER_TYPE: "customerType",
  DEPARTMENT: "department",
  CATEGORY: "category",
  STATUS: "status",
}
export const SUBSCRIPTION_PLAN_TYPE = {
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY",
  DAILY: "DAILY",
}
export const ONETIME_MESSAGE_RULES = {
  TEXTMESSAGE: "Text Message",
  IMAGE: "Image",
  VIDEO: "Video",
  DOCUMENT: "Document",
  LISTMESSAGE: "List Message",
  REPLYBUTTON: "Reply Button",
  LOCATION: "Location",
  text: "text",
  TEMPLATE: "template",
  MEDIA_SELECTION: "media",
}

export const WHATSAPP_WEB_SEARCH_FILTERS = {
  ALL_MESSAGES: "ALL_MESSAGES",
  UNREAD: "UNREAD",
  ALL_CHARTS: "ALL_CHARTS",
  PENDING: "PENDING",
  IN_REVIEW: "IN_REVIEW",
  CLOSED: "CLOSED",
  EXPIRED: "EXPIRED",
  ASSIGNED_TO_ME: "ASSIGNED_TO_ME",
  UN_ASSIGNED: "UN_ASSIGNED",
  STARRED: "STARRED",
  OPEN: "OPEN",
}

export const CHECKBOXACTION = {
  NACTIVE: 1,
  NINACTIVE: 0,
}
