import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"

import React, { createContext, lazy, Suspense, useEffect, useState } from "react"
import ReactDOM from "react-dom"
import axios from "axios"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import store, { persistStoreData } from "./store"
import "./assets/fonts/Roboto/Roboto-Regular.ttf"
import "./index.scss"
import LoadingFallback from "components/Common/Fallback"
// import UIToolKit from "ui-toolkit/UIToolKit"
const App = lazy(() => import("./App"))

export const GlobalContext = createContext()

const Main = () => {
  const [config, setConfig] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const { data } = await axios.get(`config/config.json?id=${Date.now()}`)
        setConfig(data)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    fetchConfig()
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    console.error("Error while rendering App.js", error)
    return <div>Error loading configuration.</div>
  }

  return (
    <Provider store={store}>
      <PersistGate persistor={persistStoreData().persistor}>
        <BrowserRouter>
          <GlobalContext.Provider value={config}>
            <Suspense fallback={<LoadingFallback />}>
              <App />
            </Suspense>
          </GlobalContext.Provider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

ReactDOM.render(<Main />, document.getElementById("root"))
